// Colors
$mmc_text__light: #222;
$mmc_bg__light: #fff;
$mmc_cal_dark: #ddd;
$mmc_primary: #1e73be;

// Breakpoints
// Extra small screen / phone / 600
$xs:                  480px;
// Small screen / tablet / 900
$sm:                  768px;
// Medium screen / laptop / 1200 / MacBook Air 11": 1366 x 768
$md:                  992px;
// Large screen / desktop / 1800 / 21" is HD 1920 x 1080
$lg:                  1200px;
// HD screen / desktop / 1920 / 21" is HD 1920 x 1080
$hd:                  1920px;
// 27" screen / desktop / 2560 / iMac 27" is HD 2560 x 1440
$hd2:                 2560px;
// 4k screen / desktop / 3840 / 21" is HD 3840 x 2160
$hd4:                 3840px;

// Bootstrap 3
/*
// Extra small screen / phone
$screen-xs:                  480px !default;
// Small screen / tablet
$screen-sm:                  768px !default;
// Medium screen / desktop
$screen-md:                  992px !default;
// Large screen / wide desktop
$screen-lg:                  1200px !default;
*/

// Bootstrap 4
/*
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;
*/

$grid-breakpoints: (
        xs: 480px,
        sm: 768px,
        md: 992px,
        lg: 1200px,
        hd: 1920px,
        hd2: 2560px,
        hd4: 3840px,
) !default;
