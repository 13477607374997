/*
// From Bootstrap 3
$xs: 480px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

  USAGE
  =========================
.container {
    padding: 0 15px;
// 768px window width and more
    @include sm {
        padding: 0 20px;
    }
// 1200px window width and more
    @include lg {
        margin-left: auto;
        margin-right: auto;
        max-width: 1100px;
    }
// 1400px window width and more
    @include rwd(1400) {
        margin-bottom: 20px;
        margin-top: 20px;
    }
}
 */
// Small devices
@mixin xs {
  @media (min-width: #{$xs}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$lg}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-#{opposite-direction($direction)}: ($size * 1.5) solid $color;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left:   $perpendicular-borders;
    border-right:  $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top:    $perpendicular-borders;
  }
}
