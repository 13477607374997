/**
 * Utility classes
 */
.mmc-center {
  text-align: center;
}
.mmc-rounded-red {
  border: 1px solid red;
  padding: .5em;
}
.mmc-bg-blue {
  background: $mmc_primary;
  padding: .5em;
  a.mmc-mme-link {
    color: white;
    display: block;
  }
}
.mmc-mt-2 {
  margin-top: 2em;
}

/**
 * Calendar styling
 */
.mmc-tab {}
.mmc-cal {
  padding: 3em 0;
  font-weight: 300;
  text-align: center;
  font-family: Roboto, sans-serif;
  h2 {
    font: 700 42px Oswald, sans-serif;
    text-transform: uppercase;
    padding: 25px 0;
    @include xs {
      font-size: 60px;
    }
  }
  h3 {
    margin-top: 0;
  }
  .table tr {
    &:nth-child(odd) {
      background: #f8f8f8;
    }
    th {
      font-family: Oswald, sans-serif;
      text-align: center;
      font-weight: 700;
    }
    td {
      padding: 1em 0 2em;
      position: relative;
      text-decoration: line-through;
      color: #666;
      cursor: pointer;
      @include lg {
        font-size: 1.5em;
        padding: .5em 0 1.5em;
      }
      span::after {
        content: '';
        display: block;
        width: .55em;
        height: .55em;
        border-radius: 100em;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        opacity: 1;
        margin-top: .25em;
        background: red;
      }
      &.non {
        text-decoration: none;
        color: #aaa;
        cursor: auto;
        span::after {
          opacity: 0;
        }
      }
      &.free {
        //background: #edffed;
        text-decoration: none;
        color: #000;
        font-weight: bold;
        /*
        &::before {
          border-radius: 50%;
          border: 1px solid green;
          content: '';
          width: 4rem;
          height: 4rem;
          position: absolute;
          left: 33px;
          top: 17px;
        }
        */
        span::after {
          background: green;
        }
      }
    }
  }
}
.mmc-cal__light{
  color: $mmc_text__light;
  background: $mmc_bg__light;
}
.mmc-info {
  background: #eee;
  text-align: left;
  padding-top: 1em;
  h3 {
    font-family: Oswald, sans-serif;
    font-weight: 700;
    text-align: center;
    color: $mmc_primary;
    border-bottom: 3px solid currentColor;
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    text-transform: uppercase;
  }
  h4 {
    font-family: Oswald, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    padding-left: 80px;
    margin-top: 0;
    &.mmc-free-band {
      margin: 0.8em 0 1.5em;
      @include rwd(361px) {
        margin: 0.8em 0 2.5em;
      }
      @include sm {
        margin: 0.8em 0 1.2em;
      }
      @include lg {
        margin: 1.2em 0 2em;
      }
    }
  }
  a {
    font-size: 1.5em;
    color: $mmc_primary;
  }
  img {
    vertical-align: top;
    float: left;
    width: 70px;
    &.mmc-bend{}
    &.mmc-mme-logo{
    }
  }
  .mmc-city {
    padding-left: 80px;
  }
  p {
    padding-left: 0;
    line-height: 1.4;
    @include sm {
      font-size: .8em;
    }
    @include lg {
      font-size: 1em;
    }
  }
  .mmc-event {
    overflow: auto;
    margin-bottom: 1em;
  }
  .mmc-book-free {
    border-top: 1px dashed #bbb;
    padding: 1em 0 2em;
    @include sm {
      padding: 1em 0;
    }
    @include lg {
      margin-top: 2.5em;
      padding: 1.5em 0 2em;
    }
  }
  .mmc-recommend{}
  .mmc-call-us {
    text-align: center;
  }
}
.mmc-cal-nav__next,
.mmc-cal-nav__prev {
  float: right;
  font-weight: 300;
  text-transform: uppercase;
  font-size: smaller;
  cursor: pointer;
}
.mmc-cal-nav__prev {
  float: left;
}
a.mmc-mme-link {
  @include sm {
    font-size: 1em;
  }
  @include md {
    font-size: 1.5em;
  }
}
.mmc-cal__header {
  h3 {
    cursor: pointer;
    &::after {
      @include triangle(bottom, red, .3em);
      position: absolute;
      top: .3em;
      margin-left: .3em;
    }
    @media screen and (max-width: $xs){
      font-size: 18px;
      line-height: 1.4;
    }
  }
}

/**
 * MMC month select
 */
.mmc-sel {
  position: absolute;
  width: 245px;
  background: white;
  border: 1px solid black;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  input {display: none;}
  label {
    font-weight: 400;
    padding: 0.2em 2em;
    margin: 0;
    display: block;
  }
  input:checked + label {
    background: #444;
    color: white;
  }
  &__month {
    float: left;
    border-right: 1px solid black;
  }
  &__year {float: right;}
  button {
    font-weight: 700;
    display: block;
    width: 100%;}
}
.mmc-day {
  background: #bbb;
}
.mmc-free-bands {
  text-align: center;
  .success {
    color: black;
    font-size: 1em;
    border: 1px solid red;
    padding: 2em 0;
    margin: 2em 0 1em;
    letter-spacing: 0.1em;
    font-weight: 700;
  }
  ul {
    list-style: disc;
    a {
      font-size: 1em;
      text-transform: uppercase;
      color: darkgreen;
      font-weight: 500;
      &:hover {
        color: #ff264a;
      }
    }
  }
}
.mmc-form {
  ::placeholder {
    color: inherit;
  }
  input {
    display: block;
    width: 100%;
    margin-bottom: 1em;
  }
  button {
    display: block;
    margin: 3em auto 0;
    &::before,
    &::after {
      height: 28px;
    }
  }
}

.mmc-diamond {
  h3 {
    font-family: Oswald;
    font-weight: 700;
    margin-bottom: 1em;
  }
  p {
    margin-bottom: 1em;
  }
}
